import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import {InfluencerBGSVG} from "../../components/pages/influencer/InfluencerBGSVG";
import InfluencerBookSection from "../../components/pages/influencer/InfluencerBookSection";
import {SocialProofBanner} from "../../components/pages/modemagic/SocialProofBanner";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const InfluencerPage = ({data, pageContext: {data: cmsData}}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {hero = {}} = data.allSanityModemagicPage.nodes[0] ?? {};
  const {heroInfluencerSection} = cmsData;
  const {lastSection} = data.allSanityModemagic3Page.nodes[0] ?? {};

  return (
    <Layout
      type='mason'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true}}
      hideHamMenuOnMobile
    >
      <StructuredData />

      <Buffer buffer={81} mobileBuffer={30} />

      {/* Hero Section */}
      <section
        id='influencerForm'
        className={styler.get("influencerBookWrap", "full-bleed")}
      >
        <InfluencerBGSVG className={styler.get("bgSvg")} />
        <InfluencerBookSection
          data={heroInfluencerSection}
          isMobile={isMobile}
          socialProofData={hero.socialProofHomepage}
        />
      </section>

      <div className='full-bleed influencerPageDesktopBanner'>
        {isMobile ? (
          <div className={styler.get("socialProofInfluencer")}>
            {hero.socialProofHomepage?.map((image, index) => {
              if (index < 4) {
                return (
                  <div className={styler.get(["socialProofLogo"])}>
                    <GatsbyImage
                      alt={image?.alt}
                      image={image?.image?.asset?.gatsbyImageData}
                      className={styler.get([image?.alt ?? ""])}
                      objectFit='contain'
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <SocialProofBanner
            data={[...hero.socialProofHomepage, ...hero.socialProofHomepage]}
          />
        )}
      </div>

      {/* Footer Section */}
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection data={lastSection} hideTicketSection />
      </section>
    </Layout>
  );
};

export default InfluencerPage;

export const query = graphql`
  query ($tag: String) {
    allSanitySeoMeta(filter: {page: {eq: $tag}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        hero {
          socialProofHomepage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
  }
`;
