// extracted by mini-css-extract-plugin
export var authorName = "styles-module--authorName--3qicP";
export var authorRecommends = "styles-module--authorRecommends--1Honi";
export var calendlyFrame = "styles-module--calendlyFrame--2AK_g";
export var centerAlign = "styles-module--centerAlign--v8qYX";
export var cite = "styles-module--cite--1mY-N";
export var curvedArrow = "styles-module--curvedArrow--1gCxm";
export var designation = "styles-module--designation--Vt-L7";
export var errorContainer = "styles-module--errorContainer--3_qT0";
export var formInputContainer = "styles-module--formInputContainer--b1oIX";
export var formSection = "styles-module--formSection--1tJCu";
export var formSuccessState = "styles-module--formSuccessState--1n-Sj";
export var imageRightSection = "styles-module--imageRightSection--1qdyF";
export var influencerFormCTA = "styles-module--influencerFormCTA--2JEXL";
export var influencerFormTitle = "styles-module--influencerFormTitle--3mW34";
export var influencerFormWrapper = "styles-module--influencerFormWrapper--2LsLe";
export var influencerSectionText = "styles-module--influencerSectionText--3OHv_";
export var influencerSectionTitle = "styles-module--influencerSectionTitle--2waDJ";
export var influencerSectionWrapper = "styles-module--influencerSectionWrapper--1Py5R";
export var inputField = "styles-module--inputField--15Jw5";
export var leftSectionCta = "styles-module--leftSectionCta--3UJnC";
export var newsLetterProfileImage = "styles-module--newsLetterProfileImage--1wfjy";
export var profileImage = "styles-module--profileImage--ehzuq";
export var quote = "styles-module--quote--1S3I0";
export var quoteSection = "styles-module--quoteSection--3ieJJ";
export var quoteWithImage = "styles-module--quoteWithImage--1WXib";
export var rightSectionImage = "styles-module--rightSectionImage--pqgu4";
export var successGifBG = "styles-module--successGifBG--JqZbt";
export var successGifMoney = "styles-module--successGifMoney--24mpc";
export var successOverlay = "styles-module--successOverlay--1Q1dP";