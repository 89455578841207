// extracted by mini-css-extract-plugin
export var active = "styles-module--active--3BhmS";
export var bgSvg = "styles-module--bgSvg--vX_lx";
export var check = "styles-module--check--3PHd0";
export var cta = "styles-module--cta--1nQLz";
export var dark = "styles-module--dark--MaBNY";
export var description = "styles-module--description--1fHQl";
export var dot = "styles-module--dot--3sp7D";
export var footerWrap = "styles-module--footerWrap--3SQKt";
export var influencerBookWrap = "styles-module--influencerBookWrap--3QjtY";
export var list = "styles-module--list--1Nc_7";
export var plan = "styles-module--plan--_2oOC";
export var popular = "styles-module--popular--31PyF";
export var rate = "styles-module--rate--1rWI-";
export var socialProofInfluencer = "styles-module--socialProofInfluencer--35qOO";
export var socialProofLogo = "styles-module--socialProofLogo--10zr8";
export var title = "styles-module--title--3Top3";