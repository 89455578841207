import React from "react";

export const CurvedArrow = ({
  className,
  width = 130,
  height = 57,
  color = "#010338",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 130 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      color={color}
    >
      <path
        d='M2.78459 2.75011C21.3878 42.7293 86.4942 54.3116 127.311 28.0523M127.311 28.0523L106.344 22.2208M127.311 28.0523L120.142 46.8773M38.4459 48.0126C45.0754 51.6236 63.0871 57.6935 82.0984 53.0847'
        stroke='#010338'
        strokeWidth='3.6'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
