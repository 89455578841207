import {StyleExtractor, isFunction, isValidString} from "@kubric/utils";
import DeviceDetector from "device-detector-js";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";

import {auditFormSubmission} from "../../../../utils/audit";
import SanityRichText from "../../../commons/SanityRichText";
import {AlertCircle} from "../../../icons/AlertCircle";
import {CurvedArrow} from "../../../icons/CurvedArrow";
import * as styles from "./styles.module.scss";

const deviceDetector = new DeviceDetector();

const styler = new StyleExtractor(styles);

const FormStateTypes = {
  FORM: "form",
  PROBLEM: "problem",
};

const ErrorField = ({errorMessage}) => {
  return (
    <div className={styler.get("errorContainer")}>
      <AlertCircle /> <span>{errorMessage}</span>
    </div>
  );
};

const InfluencerForm = ({influenceFormData, isMobile}) => {
  const [formState, setFormState] = React.useState(FormStateTypes.FORM);

  const {
    influencerFormTitle,
    influencerFormFullName,
    influencerFormFullNameError,
    influencerFormFullNamePlaceHolder,
    influencerFormworkEmail,
    influencerFormworkEmailPlaceholder,
    influencerFormworkError,
    influencerFormStoreURL,
    influencerFormStoreURLError,
    influencerFormStoreURLPlaceholder,
    influencerFormCTA,
    calendlyLink,
  } = influenceFormData;

  const [error, setError] = React.useState(false);

  const [formDataDesktop, setFormDataDektop] = React.useState({
    userName: "",
    email: "",
    website: "",
  });

  const [formDataMobile, setFormDataMobile] = React.useState({
    email: "",
    website: "",
  });

  let device = "desktop";
  try {
    device =
      deviceDetector.parse(window.navigator.userAgent).device.type ?? "desktop";
  } catch {
    device = "desktop";
  }

  return (
    <div className={styler.get("influencerFormWrapper")}>
      {formState === FormStateTypes.FORM &&
        (!isMobile ? (
          // Desktop Form
          <div className={styler.get("formSection")}>
            <p className={styler.get("influencerFormTitle")}>
              {influencerFormTitle}
            </p>
            <div className={styler.get("influencerForm")}>
              <div className={styler.get("formInputContainer")}>
                <p>{influencerFormFullName}</p>
                <input
                  onChange={(e) =>
                    setFormDataDektop({
                      ...formDataDesktop,
                      userName: e.target.value,
                    })
                  }
                  className={styler.get("inputField")}
                  placeholder={influencerFormFullNamePlaceHolder}
                />
                {error && !isValidString(formDataDesktop.userName) && (
                  <ErrorField errorMessage={influencerFormFullNameError} />
                )}
              </div>
              <div className={styler.get("formInputContainer")}>
                <p>{influencerFormworkEmail}</p>
                <input
                  onChange={(e) =>
                    setFormDataDektop({
                      ...formDataDesktop,
                      email: e.target.value,
                    })
                  }
                  className={styler.get("inputField")}
                  placeholder={influencerFormworkEmailPlaceholder}
                />
                {error && !isValidString(formDataDesktop.email) && (
                  <ErrorField errorMessage={influencerFormworkError} />
                )}
              </div>
              <div className={styler.get("formInputContainer")}>
                <p>{influencerFormStoreURL}</p>
                <input
                  onChange={(e) =>
                    setFormDataDektop({
                      ...formDataDesktop,
                      website: e.target.value,
                    })
                  }
                  className={styler.get("inputField")}
                  placeholder={influencerFormStoreURLPlaceholder}
                />
                {error && !isValidString(formDataDesktop.website) && (
                  <ErrorField errorMessage={influencerFormStoreURLError} />
                )}
              </div>
            </div>
            <button
              onClick={() => {
                if (
                  Object.values(formDataDesktop).some(
                    (data) => !isValidString(data)
                  )
                ) {
                  setError(true);
                } else {
                  if (isFunction(window?.gtag)) {
                    window?.gtag("event", "store_audit_submission_successful", {
                      name: formDataDesktop.userName,
                      email: formDataDesktop.email,
                      url: formDataDesktop.website,
                    });
                  }
                  auditFormSubmission({...formDataDesktop, device}).then(() =>
                    setFormState(FormStateTypes.SUCCESS_FORM)
                  );
                }
              }}
              type='button'
              id='store-audit-form-submitted'
              className={styler.get("influencerFormCTA")}
            >
              {influencerFormCTA}
            </button>
          </div>
        ) : (
          // Mobile Form
          <div className={styler.get("formSection")}>
            <div className={styler.get("influencerForm")}>
              <div className={styler.get("formInputContainer")}>
                <p>{influencerFormworkEmail}</p>
                <input
                  onChange={(e) =>
                    setFormDataMobile({
                      ...formDataMobile,
                      email: e.target.value,
                    })
                  }
                  className={styler.get("inputField")}
                  placeholder={influencerFormworkEmailPlaceholder}
                />
                {error && !isValidString(formDataMobile.email) && (
                  <ErrorField errorMessage={influencerFormworkError} />
                )}
              </div>
              <div className={styler.get("formInputContainer")}>
                <p>{influencerFormStoreURL}</p>
                <input
                  onChange={(e) =>
                    setFormDataMobile({
                      ...formDataMobile,
                      website: e.target.value,
                    })
                  }
                  className={styler.get("inputField")}
                  placeholder={influencerFormStoreURLPlaceholder}
                />
                {error && !isValidString(formDataMobile.website) && (
                  <ErrorField errorMessage={influencerFormStoreURLError} />
                )}
              </div>
            </div>
            <button
              onClick={() => {
                if (
                  Object.values(formDataMobile).some(
                    (data) => !isValidString(data)
                  )
                ) {
                  setError(true);
                } else {
                  if (isFunction(window?.gtag)) {
                    window?.gtag("event", "store_audit_submission_successful", {
                      email: formDataMobile.email,
                      url: formDataMobile.website,
                    });
                  }
                  auditFormSubmission({...formDataMobile, device}).then(() =>
                    setFormState(FormStateTypes.SUCCESS_FORM)
                  );
                }
              }}
              type='button'
              id='store-audit-form-submitted'
              className={styler.get("influencerFormCTA")}
            >
              {influencerFormCTA}
            </button>
          </div>
        ))}
      {formState === FormStateTypes.SUCCESS_FORM && (
        <div className={styler.get("formSuccessState")}>
          {!isMobile ? (
            <iframe
              src={`${calendlyLink}?hide_event_type_details=1&name=${formDataDesktop.userName}&email=${formDataDesktop.email}`}
              frameBorder='0'
              className={styler.get("calendlyFrame")}
            />
          ) : (
            // No name auto filling on mobile
            <iframe
              src={`${calendlyLink}?hide_event_type_details=1&email=${formDataMobile.email}`}
              frameBorder='0'
              className={styler.get("calendlyFrame")}
            />
          )}
        </div>
      )}
    </div>
  );
};

const InfluencerBookSection = ({data, isMobile = false}) => {
  const {
    heroInfluencerDetails,
    influencerBookContent,
    rightSection,
    rightSectionImage,
  } = data;

  const {
    leftSection,
    _rawAuthorRecommends,
    _rawTitle,
    profilePicture,
    quote,
    authorName,
    authorRole,
    authorCompany,
    description,
    textBelowDescription,
    cta,
  } = heroInfluencerDetails ?? {};

  return (
    <div className={styler.get(["influencerSectionWrapper", "centerAlign"])}>
      {/* Influencer Left Section */}
      {leftSection === "influencer" && (
        <>
          {!isMobile ? (
            <div className={styler.get("influencerSectionText")}>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawAuthorRecommends}
                className={styler.get("authorRecommends")}
              />
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawTitle}
                className={styler.get("influencerSectionTitle")}
              />
              <div className={styler.get("quoteSection")}>
                <div className={styler.get("quoteWithImage")}>
                  <GatsbyImage
                    image={profilePicture?.image?.asset?.gatsbyImageData}
                    alt={profilePicture?.image?.asset?.altText}
                    objectFit='cover'
                    className={styler.get("profileImage")}
                  />
                  <p className={styler.get("quote")}>{quote}</p>
                </div>
                <div className={styler.get("cite")}>
                  <p className={styler.get("authorName")}>{authorName}</p>
                  <p className={styler.get("designation")}>
                    <p>{authorRole}</p>
                    {authorCompany && <p>- {authorCompany}</p>}
                  </p>
                </div>
              </div>
              {cta && (
                <OutboundLink
                  href={cta?.url}
                  id={cta?.idAttribute}
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noopener noreferrer'
                  className={styler.get("leftSectionCta")}
                >
                  {cta?.text}
                </OutboundLink>
              )}
            </div>
          ) : (
            <div className={styler.get("influencerSectionText")}>
              <div className={styler.get("authorRecommends")}>
                <GatsbyImage
                  image={profilePicture?.image?.asset?.gatsbyImageData}
                  alt={profilePicture?.image?.asset?.altText}
                  objectFit='cover'
                  className={styler.get("profileImage")}
                />
                <SanityRichText
                  renderContainerOnSingleChild
                  blocks={_rawAuthorRecommends}
                />
              </div>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawTitle}
                className={styler.get("influencerSectionTitle")}
              />
              {cta && (
                <OutboundLink
                  href={cta?.url}
                  id={cta?.idAttribute}
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noopener noreferrer'
                  className={styler.get("leftSectionCta")}
                >
                  {cta?.text}
                </OutboundLink>
              )}
            </div>
          )}
        </>
      )}
      {/* News Letter Left Section */}
      {leftSection === "newsletter" && (
        <>
          {!isMobile ? (
            <div className={styler.get("influencerSectionText")}>
              <div className={styler.get("authorRecommends")}>
                <GatsbyImage
                  image={profilePicture?.image?.asset?.gatsbyImageData}
                  alt={profilePicture?.image?.asset?.altText}
                  objectFit='cover'
                  className={styler.get("newsLetterProfileImage")}
                />
                <SanityRichText
                  renderContainerOnSingleChild
                  blocks={_rawAuthorRecommends}
                />
              </div>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawTitle}
                className={styler.get("influencerSectionTitle")}
              />
              <p className={styler.get("quote")}>{description}</p>
              <p className={styler.get("quote")}>{textBelowDescription}</p>
            </div>
          ) : (
            <div className={styler.get("influencerSectionText")}>
              <div className={styler.get("authorRecommends")}>
                <GatsbyImage
                  image={profilePicture?.image?.asset?.gatsbyImageData}
                  alt={profilePicture?.image?.asset?.altText}
                  objectFit='cover'
                  className={styler.get("profileImage")}
                />
                <SanityRichText
                  renderContainerOnSingleChild
                  blocks={_rawAuthorRecommends}
                />
              </div>
              <SanityRichText
                renderContainerOnSingleChild
                blocks={_rawTitle}
                className={styler.get("influencerSectionTitle")}
              />
            </div>
          )}
        </>
      )}
      {rightSection === "image" && (
        <CurvedArrow className={styler.get("curvedArrow")} />
      )}
      {rightSection === "form" ? (
        <InfluencerForm
          influenceFormData={influencerBookContent}
          isMobile={isMobile}
        />
      ) : (
        <div className={styler.get("imageRightSection")}>
          <GatsbyImage
            image={rightSectionImage?.image?.asset?.gatsbyImageData}
            alt={rightSectionImage?.image?.asset?.altText}
            objectFit='cover'
            className={styler.get("rightSectionImage")}
          />
          {isMobile && <p className={styler.get("quote")}>{quote}</p>}
        </div>
      )}
    </div>
  );
};

export default InfluencerBookSection;
